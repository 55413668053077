<template>
  <div id="payDetails">
    <a-modal
      width="75%"
      :visible="visible"
      :title="$t('pages_pay_104')"
      :mask-closable="false"
      :closable="false"
    >
      <template #footer>
        <a-button type="primary" @click="$emit('update:visible', false)">
          {{ $t('common_text_024') }}
        </a-button>
      </template>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1200 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="details"
        />
      </div>
      <table-page
        :total="total"
        :page-opts="pageOpts"
        @update-page="updatePage"
      />
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import { _pay } from '@/api'
import { parseMoney, getTradeStatusName } from '@/util'
import TablePage from '@/components/table-page'
import i18n from '@/locale'
export default {
  name: 'PayDetails',
  components: {
    'table-page': TablePage
  },
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    applyNo: {
      type: String,
      default: () => { return '' }
    }
  },
  setup(props) {
    const state = reactive({
      loading: true,
      total: 0,
      pageOpts: {
        pageNum: 1,
        pageSize: 10
      },
      details: [],
      columns: [
        { key: 'index', dataIndex: '_index', title: i18n.global.t('pages_pay_105'), fixed: 'left', width: 100 },
        { key: 'applyNo', dataIndex: 'applyNo', title: i18n.global.t('pages_pay_106'), width: 220, ellipsis: true },
        { key: 'merSingleNo', dataIndex: 'merSingleNo', title: i18n.global.t('pages_pay_107'), width: 220, ellipsis: true },
        { key: 'remitAmount', dataIndex: 'remitAmountText', title: i18n.global.t('pages_pay_032'), width: 180 },
        { key: 'rate', dataIndex: 'rate', title: i18n.global.t('pages_pay_086'), width: 150 },
        { key: 'arrivalAmount', dataIndex: 'arrivalAmountText', title: i18n.global.t('pages_pay_033'), width: 180 },
        { key: 'tradeType', dataIndex: 'tradeTypeText', title: i18n.global.t('pages_pay_030'), width: 200 },
        { key: 'feeAmt', dataIndex: 'feeAmtText', title: i18n.global.t('pages_pay_082'), width: 180 },
        { key: 'subjectType', dataIndex: 'subjectTypeText', title: i18n.global.t('pages_pay_089'), width: 200 },
        { key: 'payeeName', dataIndex: 'payeeName', title: i18n.global.t('pages_pay_108'), width: 180, ellipsis: true },
        { key: 'payeeBankName', dataIndex: 'payeeBankName', title: i18n.global.t('pages_pay_087'), width: 220, ellipsis: true },
        { key: 'payeeBankAcct', dataIndex: 'payeeBankAcct', title: i18n.global.t('pages_pay_088'), width: 220, ellipsis: true },
        { key: 'idCard', dataIndex: 'idCard', title: i18n.global.t('pages_pay_109'), width: 220 },
        { key: 'address', dataIndex: 'address', title: i18n.global.t('pages_pay_094'), width: 150, ellipsis: true },
        { key: 'phone', dataIndex: 'phone', title: i18n.global.t('pages_pay_093'), width: 220 },
        { key: 'purpose', dataIndex: 'purpose', title: i18n.global.t('pages_pay_095'), width: 250, ellipsis: true }
      ]
    })

    watch(() => props.applyNo,
      () => loadPayDetails()
    )

    const updatePage = (page, pageSize) => {
      state.pageOpts.pageNum = page
      state.pageOpts.pageSize = pageSize
      loadPayDetails()
    }

    const loadPayDetails = async () => {
      state.loading = true
      const res = await _pay.queryOrderPaymentDetail({
        batchNo: props.applyNo,
        pageNum: state.pageOpts.pageNum,
        pageSize: state.pageOpts.pageSize
      })
      if (res.data.succ) {
        const { data } = res.data
        state.details = data.list.map((item, index) => {
          return Object.assign({}, item, {
            _index: index + 1,
            tradeTypeText: getTradeStatusName(item.tradeType, 'tradeCode'),
            subjectTypeText: getTradeStatusName(item.subjectType, 'subjectType'),
            singlePayStatusText: item.singlePayStatus ? getTradeStatusName(item.singlePayStatus, 'singlePayStatus') : '-',
            amountText: parseMoney(item.amount),
            remitAmountText: item.remitAmount ? `${item.remitCurrency} ${parseMoney(item.remitAmount)}` : '-',
            arrivalAmountText: `${item.arrivalCurrency} ${parseMoney(item.arrivalAmount)}`,
            feeAmtText: `${item.feeCurrency} ${parseMoney(item.feeAmt)}`
          })
        })
        state.total = data.total
      }
      state.loading = false
    }

    return {
      updatePage,
      ...toRefs(state)
    }
  }
}
</script>


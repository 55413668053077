<template>
  <div id="outboundsEdit">
    <a-modal
      wrap-class-name="outbounds-edit-modal"
      :title="editName !== 'view' ? `${$t('pages_pay_076')}: ${batchNo}` : $t('pages_pay_104')"
      :visible="visible"
      :width="900"
      :mask-closable="false"
      @cancel="$emit('update:visible', false)"
    >
      <div v-if="editName === 'review'" class="review-area">
        <!-- <a-alert
          v-if="reviewDisabled && !reviewLoading"
          type="error"
          banner
          style="margin: -8px 0 24px 0"
          :message="$t('pages_pay_270')"
        /> -->
        <a-spin :spinning="reviewLoading">
          <div class="detail-body-i">
            <div class="body-list">
              <div class="title">
                <money-collect-filled class="ic-transact" />
                <span>{{ $t('pages_pay_084') }}</span>
              </div>
              <div class="area-list">
                <div
                  v-for="(item, index) in infoData.list"
                  :key="index"
                  class="area"
                >
                  <span style="font-weight: 500">{{ item.name }}</span>
                  <div v-if="item.attr === 'rate'" class="extra-detail">
                    <a-spin v-if="spinLoading" size="small" :tip="$t('pages_exchange_030')" />
                    <span v-else-if="rate" class="rate">1 {{ arithmetic === 'DIV' ? curInfo.receiveCur : curInfo.payCur }} = {{ rate }} {{ arithmetic === 'DIV' ? curInfo.payCur : curInfo.receiveCur }}</span>
                    <span v-else class="rate-error">{{ resMsg }}</span>
                    <span v-if="queryDate && !loading" class="query-date">{{ $t('pages_pay_098') }} {{ queryDate }}</span>
                    <div v-if="!spinLoading && visible && curInfo.payCur !== curInfo.receiveCur" class="refresh-process">
                      <count-down
                        :valid-time="validTime"
                        :trade-currency="`${curInfo.payCur}${curInfo.receiveCur}`"
                        @refresh="loadExchangeRate"
                      />
                    </div>
                  </div>
                  <div v-else class="text">
                    <img v-if="item.img" :src="item.img">
                    <a-tooltip v-if="['bankName', 'bankAccount'].includes(item.attr)" :title="item.value">
                      <div class="ellipsis">{{ item.value }}</div>
                    </a-tooltip>
                    <a-tooltip v-else-if="['remitAmount', 'arrivalAmount'].includes(item.attr)" :title="item.value">
                      <div class="ellipsis" style="width: 100px">{{ item.value }}</div>
                    </a-tooltip>
                    <span v-else>{{ item.value }}</span>
                    <a-tooltip v-if="item.attr === 'feeAmt' && hasCoupon" :title="$t('common_text_058')">
                      <img class="img-coupon" src="~@/assets/icons/ic-coupon.png">
                    </a-tooltip>
                  </div>
                </div>
              </div>
              <div v-if="infoData.subList.length !== 0" class="area-list sub-list">
                <div
                  v-for="(item, index) in infoData.subList"
                  :key="index "
                  class="area"
                >
                  <span>{{ item.name }}</span>
                  <div class="text">
                    <span>{{ item.value }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
      <div v-else class="detail-area">
        <div class="table-part">
          <a-table
            :scroll="{ x: 1200 }"
            :loading="loading"
            :pagination="false"
            :custom-header-row="() => { return {class: 'my-table-tr'}}"
            :columns="columns"
            :data-source="records"
          />
          <table-page
            :total="total"
            :page-opts="searchQuery"
            @update-page="updatePage"
          />
        </div>
      </div>

      <template #footer>
        <div class="flex-align">
          <a-button
            v-if="editName === 'review'"
            type="primary"
            danger
            :loading="rejectLoading"
            @click="handlePass('03')"
          >{{ $t('pages_pay_100') }}</a-button>
          <div class="flex-align" style="margin-left: auto">
            <a-button @click="() => $emit('update:visible', false)">{{ $t('common_text_024') }}</a-button>
            <a-button
              v-if="editName === 'review'"
              type="primary"
              :disabled="reviewDisabled"
              :loading="passLoading"
              @click="reviewPass"
            >{{ $t('pages_pay_099') }}</a-button>
          </div>
        </div>
      </template>
    </a-modal>
    <password-modal
      v-model:visible="passwordVisible"
      @pass="handlePass('02')"
    />
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import TablePage from '@/components/table-page'
import CountDown from '@/components/count-down'
import PasswordModal from '@/components/password-modal'
import { message, Modal } from 'ant-design-vue'
import { _pay } from '@/api'
import { evalMathStr, getTradeStatusName, formatColumns, parseMoney, getCountryLogo } from '@/util'
import i18n from '@/locale'
export default {
  name: 'BankChoose',
  components: {
    'table-page': TablePage,
    'count-down': CountDown,
    'password-modal': PasswordModal
  },
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props, ctx) {
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      loading: false,
      spinLoading: false,
      reviewLoading: false,
      rejectLoading: false,
      passLoading: false,
      submitLoading: false,
      passwordVisible: false,
      reviewDisabled: true,
      hasCoupon: false,
      total: 0,
      editName: '',
      batchNo: '',
      rate: '',
      arithmetic: '',
      queryDate: '',
      resMsg: '',
      validTime: 12,
      payAmount: 0,
      receiveAmount: 0,
      amountMark: '',
      detailInfo: {},
      infoData: {
        list: [],
        subList: []
      },
      curInfo: {
        payCur: '',
        receiveCur: ''
      },
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        applyNo: ''
      },
      records: [],
      columns: []
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadOutboundDetails()
    }

    const reviewPass = async () => {
      state.passLoading = true
      const res = await _pay.isPayPwdExist()
      if (res.data.data) {
        state.passwordVisible = true
      } else {
        Modal.warning({
          title: i18n.global.t('common_text_037'),
          content: i18n.global.t('common_text_038') + i18n.global.t('common_text_039'),
          okText: i18n.global.t('common_text_039'),
          onOk() {
            router.push({ path: '/user/account_security', query: { labName: 'payPwd' }})
          }
        })
      }
      state.passLoading = false
    }

    const handlePass = async (status) => {
      if (status === '02') state.passLoading = true
      else state.rejectLoading = true
      const res = await _pay.paymentConfirm({
        status,
        batchNo: state.batchNo,
        feeRateId: state.detailInfo.feeRateId,
        amtFeeRateId: state.detailInfo.amtFeeRateId,
        payeeFeeRateId: state.detailInfo.payeeFeeRateId,
        isExchange: state.detailInfo.isExchange,
        feeRate: state.detailInfo.rate
      })
      if (res.data.succ) {
        store.dispatch('getCurList')
        message.success(i18n.global.t(status === '02' ? 'pages_pay_101' : 'pages_pay_103'))
        ctx.emit('update:visible', false)
        ctx.emit('refresh')
      } else {
        message.error(res.data.resMsg)
      }
      state.passLoading = false
      state.rejectLoading = false
    }

    const loadReviewInfo = async (batchNo) => {
      state.reviewLoading = true
      const list = []
      let subList = []
      const res = await _pay.outboundReviewDetails({ batchNo })
      if (res.data.succ) {
        const { outboundApply, outboundPayeeInfo, trialPayment } = res.data.data
        state.payAmount = outboundApply.debitAmt
        state.receiveAmount = outboundApply.paymentAmt
        state.amountMark = outboundApply.amountMark
        list.push(
          { name: i18n.global.t('pages_pay_032'), value: outboundApply['debitAmt'] ? `${outboundApply['debitCurrency']} ${parseMoney(outboundApply['debitAmt'])}` : '-', img: outboundApply['debitAmt'] ? getCountryLogo(outboundApply['debitCurrency']) : null, attr: 'debitAmt' },
          { name: i18n.global.t('pages_pay_096'), value: outboundApply['applyDate'] },
          { name: i18n.global.t('pages_pay_086'), value: 0, attr: 'rate' },
          { name: i18n.global.t('pages_pay_078'), value: outboundApply['outpayType'] },
          { name: i18n.global.t('pages_pay_081'), value: outboundApply['paymentAmt'] ? `${outboundApply['paymentCurrency']} ${parseMoney(outboundApply['paymentAmt'])}` : '-', img: outboundApply['paymentCurrency'] ? getCountryLogo(outboundApply['paymentCurrency']) : null, attr: 'paymentAmt' },
          { name: i18n.global.t('pages_pay_080'), value: outboundApply['applyCnt'] },
          { name: i18n.global.t('pages_pay_271'), value: trialPayment['feePattern'] === '00' ? i18n.global.t('pages_pay_274') : i18n.global.t('pages_pay_275') },
          { name: i18n.global.t('pages_pay_082'), value: outboundApply['feeAmt'] ? `${outboundApply['feeCurrency']} ${parseMoney(outboundApply['feeAmt'])}` : '-', img: outboundApply['feeAmt'] ? getCountryLogo(outboundApply['feeCurrency']) : null, attr: 'feeAmt' }
        )
        if (outboundApply.paymentType === '01' && outboundPayeeInfo) {
          subList.push(
            { name: i18n.global.t('pages_pay_094'), value: outboundPayeeInfo['countryCode'] },
            { name: i18n.global.t('pages_pay_089'), value: getTradeStatusName(outboundPayeeInfo['payeeType'], 'outboundPayeeType') },
            { name: i18n.global.t('pages_pay_192'), value: outboundPayeeInfo['accountName'] },
            { name: i18n.global.t('pages_pay_143'), value: outboundPayeeInfo['bankName'] },
            { name: i18n.global.t('pages_pay_193'), value: outboundPayeeInfo['accountNo'] }
          )
        } else {
          subList = []
        }
        state.infoData.list = list
        state.infoData.subList = subList
        if (outboundApply['feeCurrency']) loadCoupons(outboundApply['feeCurrency'])
        loadExchangeRate()
      } else {
        state.infoData.list = []
        state.infoData.subList = []
        if (res.data.resCode !== '999999') message.error(res.data.resMsg)
        ctx.emit('update:visible', false)
      }
    }

    const loadCoupons = async (feeCurrency) => {
      state.hasCoupon = false
      const res = await _pay.queryCoupon({ feeCurrency, busnType: 'OU' })
      if (res.data.succ) state.hasCoupon = res.data.data.length !== 0
    }

    const loadExchangeRate = async () => {
      state.spinLoading = true
      state.rate = ''
      state.arithmetic = ''
      state.queryDate = ''
      state.validTime = 12
      state.reviewDisabled = true
      const res = await _pay.getForeignPayConfirmRateInfo({ batchNo: state.batchNo })
      if (res.data.succ) {
        const { data } = res.data
        state.reviewDisabled = false
        state.resMsg = ''
        state.curInfo.payCur = data.debitCurrency
        state.curInfo.receiveCur = data.payoutCurrency
        state.rate = data.rate
        state.arithmetic = data.arithmetic
        state.queryDate = data.queryDate
        state.validTime = data.validTime || 12
        state.detailInfo = data
        if (state.arithmetic) {
          let amount = 0
          if (state.amountMark === 'remit') {
            amount = computeReceiveAmount()
            for (let index = 0; index < state.infoData.list.length; index++) {
              if (state.infoData.list[index].attr === 'paymentAmt') {
                state.infoData.list[index].value = `${state.curInfo.receiveCur} ${parseMoney(amount)}`
                break
              }
            }
          } else {
            amount = computePayAmount()
            for (let index = 0; index < state.infoData.list.length; index++) {
              if (state.infoData.list[index].attr === 'debitAmt') {
                state.infoData.list[index].value = `${state.curInfo.payCur} ${parseMoney(amount)}`
                break
              }
            }
          }
        }
      } else {
        state.resMsg = res.data.resMsg
      }
      state.spinLoading = false
      state.reviewLoading = false
    }

    const computePayAmount = () => {
      let payNumber = 0
      const { payCur } = state.curInfo
      const { rate, arithmetic, receiveAmount } = state
      if (arithmetic === 'DIV') {
        if (payCur === 'JPY') {
          payNumber = Math.round((evalMathStr(receiveAmount + '*' + rate) * 1))
        } else {
          payNumber = Math.round((evalMathStr(receiveAmount + '*' + rate) * 1) * 100) / 100
        }
      } else {
        if (payCur === 'JPY') {
          payNumber = Math.round((evalMathStr(receiveAmount + '/' + rate) * 1))
        } else {
          payNumber = Math.round((evalMathStr(receiveAmount + '/' + rate) * 1) * 100) / 100
        }
      }
      return payNumber
    }

    const computeReceiveAmount = () => {
      let receiveNumber = 0
      const { receiveCur } = state.curInfo
      const { rate, arithmetic, payAmount } = state
      if (arithmetic === 'DIV') {
        if (receiveCur === 'JPY') {
          receiveNumber = Math.round((evalMathStr(payAmount + '/' + rate) * 1))
        } else {
          receiveNumber = Math.round((evalMathStr(payAmount + '/' + rate) * 1) * 100) / 100
        }
      } else {
        if (receiveCur === 'JPY') {
          receiveNumber = Math.round((evalMathStr(payAmount + '*' + rate) * 1))
        } else {
          receiveNumber = Math.round((evalMathStr(payAmount + '*' + rate) * 1) * 100) / 100
        }
      }
      return receiveNumber
    }

    const loadOutboundDetails = async () => {
      state.loading = true
      let flag = 0
      const res = await _pay.getForeignPayConfirmDetailList(state.searchQuery)
      if (res.data.succ) {
        const { titles, pageInfo } = res.data.data
        state.records = pageInfo.list.map(item => {
          if (item.amount !== 0) flag = 1
          return Object.assign({}, item, {
            bankAcctType: item.bankAcctType === '00' ? i18n.global.t('pages_pay_207') : i18n.global.t('pages_pay_206'),
            payerType: item.payerType === '00' ? i18n.global.t('pages_pay_207') : i18n.global.t('pages_pay_206'),
            amountMarkText: getTradeStatusName(item.amountMark, 'amountMark'),
            singlePayStatus: getTradeStatusName(item.singlePayStatus, 'singlePayStatus'),
            isOpenPoboText: getTradeStatusName(String(item.isOpenPobo), 'isSameAccountName')
          })
        })
        const titlesArr = titles.filter(item => {
          if (flag === 0 && item.key === 'amount') return false
          return true
        })
        state.columns = formatColumns(titlesArr).map(item => {
          if (item.key === 'isOpenPobo') {
            return Object.assign({}, item, {
              dataIndex: 'isOpenPoboText'
            })
          }
          if (item.key === 'amountMark') {
            return Object.assign({}, item, {
              dataIndex: 'amountMarkText'
            })
          }
          return Object.assign({}, item)
        })
        state.total = pageInfo.total
      }
      state.loading = false
    }

    const loadPage = (record, editName) => {
      state.reviewDisabled = true
      state.batchNo = record.batchApplyNo
      state.editName = editName
      state.searchQuery.batchNo = record.batchApplyNo
      if (editName === 'review') loadReviewInfo(record.batchApplyNo)
      else loadOutboundDetails()
    }

    return {
      reviewPass,
      updatePage,
      loadPage,
      handlePass,
      getCountryLogo,
      parseMoney,
      loadExchangeRate,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../styles/detail-form.less';
</style>


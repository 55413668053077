<template>
  <div id="payReview">
    <a-modal
      width="53%"
      :visible="visible"
      :title="`${$t('pages_pay_076')}: ${applyNo}`"
      :mask-closable="false"
      @cancel="$emit('update:visible', false)"
    >
      <a-spin :spinning="spinLoading">
        <div class="detail-body-i">
          <div class="body-list">
            <div class="title">
              <money-collect-filled class="ic-transact" />
              <span>{{ infoData.title }}</span>
            </div>
            <div class="area-list">
              <div
                v-for="(item, index) in infoData.list"
                :key="index"
                class="area"
              >
                <span style="font-weight: 500">{{ item.name }}</span>
                <div v-if="item.attr === 'rate'" class="extra-detail">
                  <a-spin v-if="loading" size="small" :tip="$t('pages_exchange_030')" />
                  <span v-else-if="rate" class="rate">1 {{ arithmetic === 'DIV' ? curInfo.receiveCur : curInfo.payCur }} = {{ rate }} {{ arithmetic === 'DIV' ? curInfo.payCur : curInfo.receiveCur }}</span>
                  <span v-else class="rate-error">{{ resMsg }}</span>
                  <span v-if="queryDate && !loading" class="query-date">{{ $t('pages_pay_098') }} {{ queryDate }}</span>
                  <div v-if="!loading && visible && curInfo.payCur !== curInfo.receiveCur" class="refresh-process">
                    <count-down
                      :valid-time="validTime"
                      :trade-currency="`${curInfo.payCur}${curInfo.receiveCur}`"
                      @refresh="loadExchangeRate"
                    />
                  </div>
                </div>
                <div v-else class="text">
                  <img v-if="item.img" :src="item.img">
                  <a-tooltip v-if="['bankName', 'bankAccount'].includes(item.attr)" :title="item.value">
                    <div class="ellipsis">{{ item.value }}</div>
                  </a-tooltip>
                  <a-tooltip v-else-if="['remitAmount', 'arrivalAmount', 'feeAmt'].includes(item.attr)" :title="item.value">
                    <div class="ellipsis" style="width: 100px">{{ item.value }}</div>
                  </a-tooltip>
                  <span v-else>{{ item.value }}</span>
                  <a-tooltip v-if="item.attr === 'feeAmt' && hasCoupon" :title="$t('common_text_058')">
                    <img class="img-coupon" src="~@/assets/icons/ic-coupon.png">
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div v-if="infoData.subList.length !== 0" class="area-list sub-list">
              <div
                v-for="(item, index) in infoData.subList"
                :key="index "
                class="area"
              >
                <span>{{ item.name }}</span>
                <div class="text">
                  <span>{{ item.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
      <template #footer>
        <div class="flex-align">
          <a-button
            type="primary"
            danger
            style="margin-right: auto"
            @click="handleReject"
          >{{ $t('pages_pay_100') }}</a-button>
          <a-button @click="$emit('update:visible', false)">{{ $t('common_text_024') }}</a-button>
          <a-button
            type="primary"
            :loading="confirmLoading"
            @click="handleSubmit"
          >{{ $t('pages_pay_099') }}</a-button>
        </div>
      </template>
    </a-modal>
    <password-modal
      v-model:visible="passwordVisible"
      @pass="handlePass"
    />
  </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { _pay, _exchange } from '@/api'
import i18n from '@/locale'
import { message, Modal } from 'ant-design-vue'
import CountDown from '@/components/count-down'
import PasswordModal from '@/components/password-modal'
import { evalMathStr, parseMoney, getCountryLogo, getTradeStatusName } from '@/util'
export default {
  name: 'PayReview',
  components: {
    'count-down': CountDown,
    'password-modal': PasswordModal
  },
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    applyNo: {
      type: String,
      default: () => { return '' }
    },
    watchAttr: {
      type: Number,
      default: () => { return 0 }
    }
  },
  setup (props, ctx) {
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      passwordVisible: false,
      loading: false,
      spinLoading: true,
      confirmLoading: false,
      hasCoupon: false,
      rate: '',
      rateCopy: '',
      arithmetic: '',
      arithmeticCopy: '',
      queryDate: '',
      resMsg: '',
      validTime: 12,
      payAmount: 0,
      receiveAmount: 0,
      amountMark: '',
      curInfo: {
        payCur: '',
        receiveCur: ''
      },
      infoData: {
        title: i18n.global.t('pages_pay_084'),
        list: [],
        subList: []
      }
    })

    watch(() => props.watchAttr,
      () => loadPayDetail()
    )

    const handleReject = async () => {
      _pay.orderCheckConfirm({
        batchNo: props.applyNo,
        status: '03'
      })
        .then(res => {
          if (res.data.succ) {
            message.success(i18n.global.t('pages_pay_103'))
            ctx.emit('update:visible', false)
            ctx.emit('refuse')
          }
        }).catch(err => console.log(err))
    }

    const handleSubmit = async () => {
      if (!state.rate) return message.error(i18n.global.t('pages_pay_060'))
      state.rateCopy = state.rate
      state.arithmeticCopy = state.arithmetic
      state.confirmLoading = true
      const res = await _pay.isPayPwdExist()
      if (res.data.data) {
        state.passwordVisible = true
      } else {
        Modal.warning({
          title: i18n.global.t('common_text_037'),
          content: i18n.global.t('common_text_038') + i18n.global.t('common_text_039'),
          okText: i18n.global.t('common_text_039'),
          onOk() {
            router.push({ path: '/user/account_security', query: { labName: 'payPwd' }})
          }
        })
      }
      state.confirmLoading = false
    }

    const handlePass = async () => {
      state.confirmLoading = true
      const res = await _pay.orderCheckConfirm({
        batchNo: props.applyNo,
        status: '02',
        rate: state.rateCopy,
        arithmetic: state.arithmeticCopy
      })
      if (res.data.succ) {
        store.dispatch('getCurList')
        message.success(i18n.global.t('pages_pay_101'))
        ctx.emit('update:visible', false)
        ctx.emit('refresh')
      } else {
        message.error(`${i18n.global.t('pages_pay_102')}, ${res.data.resMsg}`)
      }
      state.confirmLoading = false
    }

    const loadPayDetail = async () => {
      state.infoData.list = []
      state.infoData.subList = []
      state.spinLoading = true
      const res = await _pay.queryOrderConfirmDetail({ batchNo: props.applyNo })
      if (res.data.succ) {
        const { data } = res.data
        state.curInfo.payCur = data.remitCurrency
        state.curInfo.receiveCur = data.arrivalCurrency
        state.payAmount = data.remitAmount
        state.receiveAmount = data.arrivalAmount
        state.amountMark = data.amountMark
        if (data.payType === '10') {
          state.infoData.list = [
            { name: i18n.global.t('pages_pay_032'), value: data.remitAmount ? `${data.remitCurrency} ${parseMoney(data.remitAmount)}` : '-', img: getCountryLogo(data.remitCurrency), attr: 'remitAmount' },
            { name: i18n.global.t('pages_pay_085'), value: data.payeeName || '-' },
            { name: i18n.global.t('pages_pay_086'), value: 0, attr: 'rate' },
            { name: i18n.global.t('pages_pay_087'), value: data.payeeBankName || '-', attr: 'bankName' },
            { name: i18n.global.t('pages_pay_081'), value: `${data.arrivalCurrency} ${parseMoney(data.arrivalAmount)}`, img: getCountryLogo(data.arrivalCurrency), attr: 'arrivalAmount' },
            { name: i18n.global.t('pages_pay_088'), value: data.payeeBankAcct || '-', attr: 'bankAccount' },
            { name: i18n.global.t('pages_pay_082'), value: `${data.feeCurrency} ${parseMoney(data.feeAmt)}`, img: getCountryLogo(data.feeCurrency), attr: 'feeAmt' },
            { name: i18n.global.t('pages_pay_030'), value: getTradeStatusName(data.tradeType, 'tradeCode') }
          ]
          state.infoData.subList = [
            { name: i18n.global.t('pages_pay_089'), value: getTradeStatusName(data.subjectType, 'subjectType') },
            { name: i18n.global.t('pages_pay_090'), value: data.paymentType },
            { name: data.subjectType === '00' ? i18n.global.t('pages_pay_091') : i18n.global.t('pages_pay_092'), value: data.idCard },
            { name: i18n.global.t('pages_pay_093'), value: data.phone },
            { name: i18n.global.t('pages_pay_094'), value: data.address },
            { name: i18n.global.t('pages_pay_095'), value: data.purpose || '-' }
          ]
        } else {
          state.infoData.list = [
            { name: i18n.global.t('pages_pay_032'), value: data.remitAmount ? `${data.remitCurrency} ${parseMoney(data.remitAmount)}` : '-', img: getCountryLogo(data.remitCurrency), attr: 'remitAmount' },
            { name: i18n.global.t('pages_pay_096'), value: data.createTime },
            { name: i18n.global.t('pages_pay_081'), value: `${data.arrivalCurrency} ${parseMoney(data.arrivalAmount)}`, img: getCountryLogo(data.arrivalCurrency), attr: 'arrivalAmount' },
            { name: i18n.global.t('pages_pay_086'), value: 0, attr: 'rate' },
            { name: i18n.global.t('pages_pay_097'), value: data.applyCnt },
            { name: i18n.global.t('pages_pay_082'), value: `${data.feeCurrency} ${parseMoney(data.feeAmt)}`, img: getCountryLogo(data.feeCurrency), attr: 'feeAmt' }
          ]
          state.infoData.subList = []
        }
        if (data.feeCurrency) loadCoupons(data.feeCurrency)
        loadExchangeRate()
      } else {
        message.error(res.data.resMsg)
        ctx.emit('update:visible', false)
      }
      state.spinLoading = false
    }

    const loadCoupons = async (feeCurrency) => {
      state.hasCoupon = false
      const res = await _pay.queryCoupon({ feeCurrency, busnType: 'IU' })
      if (res.data.succ) state.hasCoupon = res.data.data.length !== 0
    }

    const loadExchangeRate = async () => {
      state.loading = true
      const { payCur, receiveCur } = state.curInfo
      const res = await _exchange.getExchangeRate({ buyCurrency: receiveCur, sellCurrency: payCur })
      if (res.data.succ) {
        const { data } = res.data
        state.resMsg = ''
        state.rate = data.rate
        state.arithmetic = data.arithmetic
        state.queryDate = data.queryDate
        state.validTime = data.validTime ? data.validTime * (data.validUnit === 'HH' ? 3600 : data.validUnit === 'MM' ? 60 : 1) : 12
        if (state.arithmetic) {
          let amount = 0
          if (state.amountMark === 'remit') {
            amount = computeReceiveAmount()
            for (let index = 0; index < state.infoData.list.length; index++) {
              if (state.infoData.list[index].attr === 'arrivalAmount') {
                state.infoData.list[index].value = `${state.curInfo.receiveCur} ${amount}`
                break
              }
            }
          } else {
            amount = computePayAmount()
            for (let index = 0; index < state.infoData.list.length; index++) {
              if (state.infoData.list[index].attr === 'remitAmount') {
                state.infoData.list[index].value = `${state.curInfo.payCur} ${amount}`
                break
              }
            }
          }
        }
      } else {
        state.resMsg = res.data.resMsg
        state.rate = ''
        state.arithmetic = ''
        state.queryDate = ''
        state.validTime = 12
      }
      state.loading = false
    }

    const computePayAmount = () => {
      let payNumber = 0
      const { payCur } = state.curInfo
      const { rate, arithmetic, receiveAmount } = state
      if (arithmetic === 'DIV') {
        if (payCur === 'JPY') {
          payNumber = Math.round((evalMathStr(receiveAmount + '*' + rate) * 1))
        } else {
          payNumber = Math.round((evalMathStr(receiveAmount + '*' + rate) * 1) * 100) / 100
        }
      } else {
        if (payCur === 'JPY') {
          payNumber = Math.round((evalMathStr(receiveAmount + '/' + rate) * 1))
        } else {
          payNumber = Math.round((evalMathStr(receiveAmount + '/' + rate) * 1) * 100) / 100
        }
      }
      return payNumber
    }

    const computeReceiveAmount = () => {
      let receiveNumber = 0
      const { receiveCur } = state.curInfo
      const { rate, arithmetic, payAmount } = state
      if (arithmetic === 'DIV') {
        if (receiveCur === 'JPY') {
          receiveNumber = Math.round((evalMathStr(payAmount + '/' + rate) * 1))
        } else {
          receiveNumber = Math.round((evalMathStr(payAmount + '/' + rate) * 1) * 100) / 100
        }
      } else {
        if (receiveCur === 'JPY') {
          receiveNumber = Math.round((evalMathStr(payAmount + '*' + rate) * 1))
        } else {
          receiveNumber = Math.round((evalMathStr(payAmount + '*' + rate) * 1) * 100) / 100
        }
      }
      return receiveNumber
    }

    return {
      handlePass,
      handleSubmit,
      handleReject,
      loadPayDetail,
      loadExchangeRate,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../styles/detail-form.less';
</style>
